
    .showPicture{
        margin-top: 40px;
        padding: 20px;
        background: #F5F7F9;

    }
    .hint{
        display: block;
        margin-top: 20px;
        color: #999999;
        font-size: 14px;
        font-weight: 500;
    }
    .uploadBtn{
        margin-top: 20px;
        cursor: pointer;
        width: 71px;
        height: 71px;
        border: 1px solid #E5E5E5;
        background: #FFFFFF;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
            color: #999999;
            font-size: 12px;
            font-weight: 500;
            line-height: 1;
        }
        i{
            font-size: 18px;
            color: #BFBFBF;
            line-height: 1;
        }
    }
    .newLine{
        display: flex;
    }
    .storeGoodsAdd-title{
        color: #333333;
        font-weight: bold;
        font-size: 16px;
    }
    .storeGoodsAdd-middle-span{
        padding-top: 18px;
        color: #333333;
        font-weight: bold;
        font-size: 14px;
        span:nth-child(1){
            width: 3px;
            height: 10px;
            background: #FF4D77;
            display: inline-block;
        }
        span:nth-child(2){
            margin-left: 10px;
        }
        .storeGoodsAdd-form{
            margin-top: 20px;
        }
    }
    .upladImgBox{
        /*padding-top: 20px;*/
        display: flex;
        .upladImgBoxTxt{
            position: absolute;
            left: 0;
            top: 0;
            font-size: 12px;
        }
        .upladImgBox-item{
            position: relative;
            margin-left: 20px;
            i{
                position: absolute;
                right:-8px;
                top: -18px;
                color: #d81e06;
                font-size: 12px;
                cursor: pointer;
            }
            img{
                width: 71px;
                height: 71px;
                border: 1px solid #E5E5E5;
            }
        }
        .upladImgBox-item:nth-child(1){
            margin-left: 0;
        }
    }
    #editor-describePc,#editor-describePhone{
        width: 800px;
    }
    /deep/.w-e-toolbar{
        height: 40px;
        line-height: 30px;
        font-size: 12px;
    }
    ::-webkit-scrollbar {

        width: 0px;

        height: 2px;

        background-color: #ccc;

    }
    .serviceCmmodityAdd{
        height: calc( 100vh - 210px);
        padding: 30px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        .createStepProgress{
            padding: 30px 0px 100px 0px;
            margin: 10px auto;
            display: flex;
            align-items: center;
            .step-num{
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                span:nth-child(1){
                    display: inline-block;
                    width: 48px;
                    height: 48px;
                    font-size: 24px;
                    color: #FFFFFF;
                    background: #DCDCDC;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 48px;
                }
                .active{
                    background: #1122D8!important;
                }
                span:nth-child(2){
                    display: inline-block;
                    width: 100px;
                    text-align: center;
                    top: 68px;
                    /*left: 30%;*/
                    position: absolute;
                    font-size: 16px;
                    color: #333333;

                }
            }
            .step-wire{
                height: 14px;
                margin: 0 10px;
            }
        }
        .createStepSucceed-icon{
            .success-content {
                margin-top: 155px;
                text-align: center;
                .iconfont {
                    color: #1FD18C;
                    font-size: 52px;
                }
                .text {
                    font-size: 24px;
                    margin: 20px 0 0;
                    font-weight: bold;
                }
            }
            .btn{
                margin-top: 60px;
                text-align: center;
            }
        }
        .serviceCmmodityAdd-form{
            margin-top: 20px;
            .createStepProgress-hint{
                display: flex;
                flex-direction: column;
                padding-left: 140px;
                span{
                    font-size: 12px;
                    line-height: 24px;
                    /*color: ;*/
                }
            }
        }
        .createStepBottom{
            margin-top: 60px;
            text-align: center;
        }
    }
