
    /deep/.el-breadcrumb__item .el-breadcrumb__inner{
        color: #303133;
    }
    .serviceAdmin-table{
        /deep/th{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
        /deep/td{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
    }
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-checkbox-group{
        /*display: flex;*/
        /*justify-content: space-between;*/
        /*flex-wrap: wrap;*/
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .serviceAdmin{
        /*height: 100%;*/
        height:100%;
        padding: 30px;
        .serviceAdmin-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
            line-height: 40px;
            .el-breadcrumb {
                line-height: 40px;
            }
            div{
                display: flex;
                justify-content: start;
                .newClientBtn{
                    width: 120px;
                    color: #ffffff;
                    background: #1122D8;
                    border-radius:4px;
                    border: none;
                    margin-left: 10px;
                }
            }
        }
    }
